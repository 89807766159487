import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { ActivatedRoute, RouterModule, Router, NavigationExtras } from '@angular/router';
import { AnalyticsService } from '../../services/analytics.service';
import { ProductService } from '../../services/product.service';
import { UserService } from '../../services/user.service';
import { PurchaseService } from '../../services/purchase.service';
import { ModalService } from '../../services/modal.service';
import { ActionSheetController } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit, OnChanges {
  constructor(
    private translate: TranslateService,
    private iab: InAppBrowser,
    private router: Router,
    private analyticsService: AnalyticsService,
    private productService: ProductService,
    private purchaseService: PurchaseService,
    private userService: UserService,
    private modalService: ModalService,
    private actionSheetController: ActionSheetController
  ) { }

  @Input() productType: 'vertical' | 'list' | 'card' | 'featured';
  @Input() item: any;
  @Input() radio: boolean;
  @Input() viewOnClick: boolean;
  @Input() onlineOffline: boolean;
  @Input() sectionSkus: any;
  @Input()
  get user(): any {
    return this._user;
  }
  set user(value) {
    this._user = value;
    this.checkSkuType();
  }
  _user: any;
  product: any;
  rcSku: any;
  skuVariation: 'premium_deck' | 'default_deck' | 'rune_deck';
  category: string;
  owned: boolean;
  campaign: string = '?utm_source=laby-v2&utm_medium=app&utm_campaign=shop-tab';
  web: boolean = Capacitor.getPlatform() == 'web';

  navigate() {
    if (this.category == 'tarot' || this.category == 'lenormand' || this.category == 'astro' || this.category == 'rune') {
      // default action = buy
      // if owned = view meaning
      // if not product, go to url
      if (this.owned) {
        this.navMeanings();
      } else {
        this.navPreview();
      }
    } else {
      this.openBrowser(this.product.url);
    }
  }

  navPreview() {
    var routerURL: string;
    let navigationExtras: NavigationExtras = {
      state: {
        rcSku: this.rcSku
      }
    };

    console.log(this.rcSku);

    routerURL = 'preview-deck/' + this.category + '/' + this.product.id;
    this.analyticsService.logEvent('paywall', {
      content_type: 'preview_deck',
      content_id: this.product.id,
      source: 'tab_shop'
    });

    this.router.navigate([routerURL], navigationExtras);
  }

  navMeanings() {
    var routerURL: string;
    let navigationExtras: NavigationExtras = {
      state: {
        userDeck: this.product.id,
        section: this.category
      }
    };

    routerURL = 'tabs/tab-meanings/';
    this.analyticsService.logEvent('select_content', {
      content_type: 'meanings',
      content_id: this.product.id,
      source: 'tab_shop'
    });

    this.router.navigate([routerURL], navigationExtras);
  }

  checkViewOnClick(deckId: string) {
    if (this.viewOnClick) {
      this.navigate();
    };
  }

  async purchase(sku: any, runeDeck?: string) {
    // for rune decks, user needs an account.
    if (runeDeck && !this.user?.email) {
      return this.modalService.needsAccount('rune');
    }
    if (this.owned) {
      return this.purchaseService.showPurchasedAlert(sku);
    }

    // Log the analytics event for the start of the purchase
    this.analyticsService.logEvent('start_purchase', {
      content_id: 'deck',
      sku: sku.skuId,
      source: this.router.url,
    });

    // Determine the appropriate purchase method
    const purchaseMethod = this.web
      ? this.purchaseService.purchaseWeb
      : this.purchaseService.purchase;

    // Execute the purchase with the correct parameters
    return runeDeck
      ? await purchaseMethod.call(this.purchaseService, sku, runeDeck)
      : await purchaseMethod.call(this.purchaseService, sku);
  }

  checkSkuType() {
    if (!this.item) return;
    this.category = this.item.category;
    if (this.item.identifier) {
      // load rcSku first, then product (most tarot decks except featured)
      this.rcSku = this.item;
      const deckId = this.item.identifier.replace('$rc_', '').toUpperCase();
      this.product = this.productService.getDeck(this.category, deckId);
      this.owned = this.checkOwned(this.product);
    } else {
      // load product first, then rcSku (most rune decks, and featured tarot)
      this.product = this.productService.getDeck(this.category, this.item.id);
      this.purchaseService.getOfferings().subscribe((res) => {
        if (this.category == 'rune') {
          this.rcSku = res[this.category]?.availablePackages[0];
        } else {
          this.rcSku = res[this.category]?.availablePackages.find(p => p.identifier == '$rc_' + this.product.id.toLowerCase());
        }
      });
      this.owned = this.checkOwned(this.product);
    }
    if (this.productType == 'featured') {
      console.log(this.product, this.rcSku, this.owned, this.item, this.category);
    }
  }

  checkOwned(product: any) {
    // only checks ownership so all I need is a deck id -- deck id will be different based on non-rune or not - needs to work also for default decks.
    if (this.product.publisher == 'labyrinthos_premium' && this.user?.permissions?.features.includes('premium')) {
      return true;
    } else {
      // non-rune stuff
      if (this.product.id) {
        if (this.user?.permissions?.decks?.indexOf(this.product.id) == -1) {
          if (this.product.publisher == 'labyrinthos') {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    }
  }

  openBrowser(url) {
    const browser = this.iab.create(url+this.campaign, '_system');
    this.analyticsService.logEvent('visit_website', {
      content_type: 'product',
      content_id: url
    });
  }

  setDeck(category, deck) {
    this.user[category + 'Deck'] = deck;
    this.analyticsService.logEvent('change_settings', {
      content_category: 'deck',
      content_id: deck,
      source: 'tab-shop'
    });

    this.userService.setUser(this.user).then((data) => {
      this.userService.publishSubscription('userSubscription', this.user);
    });
  }

  checkDefault() {
    // if deck is default, set as default should be disabled.
    if (this.user && this.user[this.category + 'Deck'] == this.product.id) {
      return 'disabled';
    };
  }

  async ownedDeckOptions(ev: any) {
    ev.stopPropagation();
    const actionSheet = await this.actionSheetController.create({
      header: this.product.name,
      mode: 'md',
      buttons: [
        {
          text: this.translate.instant('tab_shop.product.default', { shopSection: this.category.charAt(0).toUpperCase() + this.category.slice(1) }),
          cssClass: this.checkDefault(),
          handler: () => {
            this.setDeck(this.category, this.product.id);
          }
        },
        {
          text: this.translate.instant('tab_shop.product.buy'),
          handler: () => {
            this.openBrowser(this.product.url);
          }
        },
        {
          text: this.translate.instant('tab_shop.product.about'),
          handler: () => {
            this.navPreview();
          }
        },
        {
          text: this.translate.instant('tab_shop.product.meanings'),
          handler: () => {
            let navTransition = actionSheet.dismiss();
            this.navMeanings();
          }
        }
      ],
    });

    await actionSheet.present();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.item || changes.sectionSkus) {
      this.checkSkuType();
    }
  }

  ngOnInit() {
    this.checkSkuType();
  }

}
